.switcherContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  height: 40px;
  padding: 2px;
  background-color: white;
  border: 1px solid var(--color-gray-100);
  border-radius: 4px;
  box-shadow: var(--bg-shadow);
  font-weight: 500;
  color: var(--color-gray-600);
  cursor: pointer;
}

.switcherStatType {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 94px;
  text-transform: capitalize;

  &Active {
    color: var(--color-purple-100);
    background-color: var(--color-pirple-light-100);
    border-radius: 4px;
    transition: background-color .2s;
  }

  &Disabled {
    background-color: var(--color-disabled);
  }
}
