.scoresButtonsWrapper {
    display: flex;
    height: auto;
    align-items: center;
    padding: 6px 0;
    margin-bottom: 12px;
    width: fit-content;
    margin-right: 10px;
  }
  
  .buttonStart {
    display: flex;
    align-items: center;
    height: 40px;
    box-shadow: var(--bg-shadow);
    padding: 0 10px;
    border: solid 1px var(--color-gray-300);
    background-color: var(--bg-color);
    color: var(--color-gray-600);
    font-weight: 500;
    white-space: nowrap;
    border-left: none;
    margin-left: 10px;
  }
  
  .buttonEnd {
    display: flex;
    align-items: center;
    height: 40px;
    box-shadow: var(--bg-shadow);
    padding: 0 10px;
    border: solid 1px var(--color-gray-300);
    background-color: var(--bg-color);
    color: var(--color-gray-600);
    font-weight: 500;
    white-space: nowrap;
    border-left: none;
  }
  