.tooltip {
  --tooltip-bg-color: #fff;
  --tooltip-size: 1rem;
  position: relative;
  outline: none;
}

.tooltip::after,
.tooltip::before {
  position: absolute;
  display: none;
  opacity: 0;
  transition: opacity 0.2s linear 0s;
  visibility: hidden;
  outline: none;
  text-decoration: none;
}

.tooltip[data-placement="left"]::after {
  padding: 1rem;
  background-color: var(--tooltip-bg-color);
  color: var(--color-gray-800);
  white-space: nowrap;
  content: attr(data-tooltip);
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2rem;
  box-shadow: 0 4px 1.4rem 0 rgba(0, 0, 0, 0.2);
  border: solid 1px var(--color-gray-300);
}

.tooltip[data-placement="bottom"]::before,
.tooltip[data-placement="right"]::before,
.tooltip[data-placement="top"]::before {
  padding: 1rem;
  background-color: var(--tooltip-bg-color);
  color: var(--color-gray-800);
  content: attr(data-tooltip);
  white-space: nowrap;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2rem;
  box-shadow: 0 4px 1.4rem 0 rgba(0, 0, 0, 0.2);
  border: solid 1px var(--color-gray-300);
}

.tooltip[data-trigger="hover"]:hover::before,
.tooltip[data-trigger="hover"]:hover::after,
.tooltip[data-trigger="focus"]:focus::before,
.tooltip[data-trigger="focus"]:focus::after {
  display: block;
  opacity: 1;
  transition: opacity 0.2s linear 0s;
  visibility: visible;
  z-index: 1000;
}

.tooltip[data-placement="top"]::after,
.tooltip[data-placement="top"]::before {
  bottom: 100%;
}

.tooltip[data-placement="top"]::before {
  left: 50%;
  margin: 0 0 .6rem 0;
  border-radius: 0.4rem;
  transform: translate(-50%);
}

.tooltip[data-placement="top"]::after {
  left: 50%;
  transform: translate(-50%);
  border-right: var(--tooltip-size) solid transparent;
  border-left: var(--tooltip-size) solid transparent;
  border-top: var(--tooltip-size) solid var(--tooltip-bg-color);
  content: "";
  margin: 0 0 0.1rem 0;
}

.tooltip[data-placement="right"]::after,
.tooltip[data-placement="right"]::before {
  left: 100%;
}

.tooltip[data-placement="right"]::before {
  top: 50%;
  margin: 0 0 0 .6rem;
  border-radius: 0.4rem;
  transform: translate(0, -50%);
}

.tooltip[data-placement="right"]::after {
  top: 50%;
  transform: translate(0, -50%);
  margin: 0 0 0 .2rem;
  border-bottom: var(--tooltip-size) solid transparent;
  border-top: var(--tooltip-size) solid transparent;
  border-right: var(--tooltip-size) solid var(--tooltip-bg-color);
  content: "";
}

.tooltip[data-placement="bottom"]::after,
.tooltip[data-placement="bottom"]::before {
  top: 100%;
}

.tooltip[data-placement="bottom"]::before {
  left: 50%;
  margin: 0.6rem 0 0 0;
  border-radius: 0.4rem;
  transform: translate(-50%);
}

.tooltip[data-placement="bottom"]::after {
  left: 50%;
  transform: translate(-50%);
  margin: .2rem 0 0 0;
  border-right: var(--tooltip-size) solid transparent;
  border-left: var(--tooltip-size) solid transparent;
  border-bottom: var(--tooltip-size) solid var(--tooltip-bg-color);
  content: "";
}

.tooltip[data-placement="left"]::after,
.tooltip[data-placement="left"]::before {
  right: 100%;
}

.tooltip[data-placement="left"]::after {
  top: 50%;
  transform: translate(0, -50%);
  margin: 0 .6rem 0 0;
  border-radius: 0.4rem;
}

.tooltip[data-placement="left"]::before {
  top: 50%;
  transform: translate(0, -50%);
  margin: 0 0.2rem 0 0;
  border-bottom: var(--tooltip-size) solid transparent;
  border-top: var(--tooltip-size) solid transparent;
  border-left: var(--tooltip-size) solid var(--tooltip-bg-color);
  content: "";
}
