@import '../../static/styles/_mixins.css';

.casesContainer {
  @mixin casesContainer;
}

.paperWidgetContent {
  padding: 8px 12px;
  position: relative;
}

.paperWidgetWrapper {
  flex: 1;
}

.downloadBtn {
  top: 10px;
  right: 36px;
}

.casesContainerTitle {
  white-space: nowrap;
}

.reportContainer {
  flex: 1;
}

.leftOffset {
  margin-left: 28px;
}

.emptyDataWrapper {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}
