.controlsContainer {
  display: flex;
  width: 100%;
  margin-bottom: 24px;
}

.grid {
  flex: 1;

  &Middle {
    flex: 1;
    margin-right: 12px;
    margin-left: 12px;
  }
}

