.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--bg-header-color);
  z-index: 20;
  margin-bottom: 16px;
  padding-bottom: 6px;
  width: 100%;

  position: sticky;
  top: -82px;
}

.headerTop {
  display: flex;
  width: 100%;
  border-bottom: 1px solid var(--color-purple-100);

  .container {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 12px 0;
  }

  &Menu {
    display: flex;
    justify-content: flex-end;
  }

  &Nav {
    display: flex;
    align-items: center;
    padding-right: 16px;
    border-right: 1px solid var(--color-purple-100);
  }
}

.headerLogo {
  position: sticky;
  top: 0;
}

.navItem {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;

  margin-right: 24px;

  opacity: .7;
  cursor: pointer;
  transition: .2s opacity;

  &:last-child {
    margin-right: 0;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -13px;

    width: 100%;
    height: 1px;

    background-color: transparent;

    transition: .2s background-color;
  }

  &:hover,
  &.active {
    opacity: 1;
  }

  &.active::after,
  &:hover::after{
    background-color: #fff;
  }

  &.disabled {
    pointer-events: none;
    cursor: none;
  }

  &Link {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
  }
}

.menuItemIcon {
  display: flex;
  align-items: center;

  padding: 0 24px;
  color: #fff;
}

.userMenuWrapper {
  position: relative;
  margin-right: 10px;

  .avatar {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 40px;
    min-width: 40px;
    height: 40px;

    background: #fff;

    border: none;
    border-radius: 50%;

    font-size: 16px;
    font-weight: 600;
    line-height: 0;
    text-transform: uppercase;
    color: var(--color-purple-300);

    cursor: pointer;
  }

  .userMenu {
    position: absolute;
    right: 0;

    display: none;

    margin-top: 8px;
    padding: 8px 16px;

    border-radius: 10px;
    background: var(--bg-light-color);

    z-index: 1;

    &.open {
      display: block;
    }
  }

  .userMenuItem {
    display: flex;
    align-items: center;

    margin-bottom: 8px;

    border: none;
    background: inherit;

    font-weight: 700;
    font-size: 14px;
    color: var(--color-gray-600);

    transition: .2s color;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      color: var(--color-purple-100);
    }

    &.disabled {
      pointer-events: none;
      opacity: .7;
    }

    &Icon {
      width: 18px;
      height: 18px;
      margin-right: 8px;
    }

    &Name {
      white-space: nowrap;
    }
  }
}

.headerBottom {
  display: flex;
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;
  padding: 28px 0 0;

  &Sticky {
    position: sticky;
    box-shadow: var(--bg-shadow-purple-dark);
  }
}

.logo {
  width: 48px;
  height: 48px;
  margin-right: 34px;
}

.cumulativeDates {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  color: white;
  height: 40px;
  justify-content: center;
  margin: 0 10px;
  line-height: 17px;
}

.title {
  margin-right: 12px;
  opacity: .5;
  font-size: 12px;
}

.breadcrumbs {
  display: flex;
  align-items: center;
  color: white;
  font-weight: bold;
  font-size: 14px;

  &Link {
    color: white;
    text-decoration: none;
    cursor: pointer;
  }

  &Element {
    padding: 0 10px;
  }
}

.icon {
  width: 16px;
  height: 16px;
}

.btnGroup {
  display: flex;
  margin-left: auto;
  flex-wrap: wrap;
  justify-content: flex-end;
  & > div {
    margin-bottom: 7px;
  }
  &LastBtn {
    margin-right: 0;
  }
}

.selectTopClassName {
  min-width: 160px;
}

.reportButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 40px;
  background-color: var(--color-purple-100);
  color: white;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 10px;
  padding: 0 12px;
  font-weight: bold;
}

.exitButton {
  padding-right: 4px;
}

.exitIcon {
  width: 28px;
  height: 28px;
}

.reportIconWrapper {
    margin-right: 6px;
}

.reportIcon {
  width: 22px;
  height: 22px;
  margin-right: 6px !important;
}

.disabled {
  opacity: 0.6;
  cursor: default;
  pointer-events: none;
}

.languageSwitcher {
  margin: 0;
  border-right: 1px solid var(--color-purple-100);
}

.languageSelect {
  display: flex;
  align-items: center;
  justify-content: center;

  width: fit-content;
  height: 40px;

  margin: 0 10px;
  padding: 0 12px;

  border: none;
  border-radius: 4px;

  background-color: transparent;

  text-transform: uppercase;
  color: white;
  font-weight: 700;

  cursor: pointer;

  &Option {
    border: 0;
    padding: 12px 0;

    background: var(--color-purple-100);

    &:hover {
      background: var(--color-purple-700);
    }
  }
}

.resetAllButton {
  font-size: 14px;
}

.modalConfirmation {
  background: var(--bg-light-color);
}
