.chartLegend {
  padding: 0;
  margin-top: 20px;
}

.legendGroup {
  display: flex;
  flex-direction: row;
  cursor: default;
  margin-bottom: 4px;

  &Container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: auto;
    width: auto;
    align-self: center;
    height: 45px;
  }

  &Name, &Count {
    font-size: 13px;
    line-height: 11px;
    margin-right: 8px;
    white-space: nowrap;
  }

  &Count {
    font-weight: bold;
    color: var(--color-gray-800);
  }
}

.circle {
  display: inline-flex;
  min-width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 8px 0 8px;
  background-color: var(--color-gray-600);
}
