.filterGroupTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 40px;
}

.title {
  font-weight: bold;
  white-space: nowrap;
}

.subtitle {
  font-size: 14px;
  color: var(--color-gray-400);
  margin-top: 4px;
}
