.caseVolumeContainer {
  display: flex;
  flex-direction: row;
}

.caseVolumeControls {
  display: flex;

  &Wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 40px;
    flex: 3;
    height: 40px;
    position: relative;
  }
}

.reportClassesTitle {
  width: 300px;
}
