.container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.title {
  color: var(--color-purple-100);
  margin-right: 4px;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.8em;
}

.icon {
  color: var(--color-purple-100);
}
