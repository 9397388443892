.content {
    display: flex;
    flex-direction: column;
    z-index: 0;
}

.row {
    display: grid;
    background-color: transparent;
    grid-template-columns: minmax(auto, 90px) minmax(180px, 250px) minmax(180px, 215px) minmax(180px, 200px) minmax(180px, 200px) minmax(180px, 200px) minmax(auto, 135px);
    grid-column-gap: 3px;
    grid-auto-rows: 50px;
    border-bottom: 1px solid var(--color-gray-200);
    overflow-wrap: break-word;
    white-space: normal;
    font-size: 12px;
    line-height: 1.25;
    overflow: hidden;

    &:hover {
        cursor: pointer;
        background: var(--color-gray-200); 
    }

    .rowPositionCenter{
        display: flex;
        align-items: center;
        font-weight: 500;
        padding: 0 7px 0 0;

        &.Green {
            color: var(--color-green-500);
        }
          
        &.Red {
            color: var(--color-red-400);
        }
    }

    .textPosition{
        margin: 0;
        overflow-wrap: break-word;
        text-overflow: ellipsis;
        line-height: 1.6;
        padding: 7px 14px 0 0;
        font-weight: 500;
    }
}

.rank {
    padding: 8px;
    border-radius: 5px;
    margin-left: 10px;
    font-weight: 600;
    background-color: var(--color-yellow-400-opacity-16);
    color: var(--color-yellow-400);
    width: 40px;
    display: flex;
    justify-content: center;

    &.BlueRank {
        background-color: var(--color-purple-100-opacity-16);
        color: var(--color-purple-100);
    }
      
    &.RedRank {
        background-color: var(--color-red-400-opacity-16);
        color: var(--color-red-400);
    }
}

.emptyTable {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 300px;
}