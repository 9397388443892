:root {
    --modal-overlay-background: rgba(24, 28, 36, 0.81);
    --overlay-zindex: 1000;
    --overlay-content-zindex: calc(var(--overlay-zindex) + 1);
}

.overlay {
    transition: all 200ms;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5rem;
    position: fixed;
    z-index: var(--overlay-zindex);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--modal-overlay-background);
    overflow: auto;
}

.modalWindow {
    width: 60rem;
    background-color: var(--bg-color);
    border-radius: 1rem;
    margin: auto;
}

/** without global will not work all csstransitions options for modal window */
:global(.modal-enter) {
    .modalWindow {
        animation: modal-open 200ms forwards cubic-bezier(0, 1, 1, 1);
    }
}

:global(.modal-exit) {
    &.overlay {
        animation: fade-out 200ms forwards cubic-bezier(0, 1, 1, 1);
    }

    .modalWindow {
        animation: modal-close 200ms forwards cubic-bezier(0, 1, 1, 1);
    }
}

@keyframes modal-open {
    0% {
        opacity: 0;
        transform: scale(0.8);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}


@keyframes modal-close {
    0% {
        opacity: 1;
    }
    to {
        transform: scale(0.9);
    }
}


@keyframes fade-out {
    0% {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
