.containerModal {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 100;
  opacity: 0.5;
  background-color: var(--color-gray-200);
  overflow-y: hidden;
  display: flex;
  justify-content: center;
}

.containerContent {
  width: 100%;
  height: fit-content;
  z-index: 101;
  margin-top: 140px;
  position: fixed;
  max-height: 532px;
  max-width: 1440px;
  min-width: 1366px;
  left: 50%;
  transform: translateX(-50%);

  &Wrapper{
    height: fit-content;
    margin: 0 16px;
    border-radius: 12px;
    overflow-y: hidden;
    background: var(--color-purple-100);
    display: flex;
    flex-direction: column;
    box-shadow: 0 24px 32px 0 rgba(76, 80, 100, 0.16);

    &Header {
      margin: 0 40px;
      display: flex;
      z-index: 102;
      align-content: center;
      align-items: center;
      height: 56px;

      &Text {
       display: flex;
       align-content: center;
       align-items: flex-end;

        .title {
          font-size: 24px;
          font-weight: bold;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          color: #fff;
        }
        
        .arrowLeft {
          color: var(--primary-text-color);
          cursor: pointer;
        }
      }

      &Buttons {
        display: flex;
        margin-right: 16px;
      }
    }

    &Items {
       margin: 0 4px 4px 4px;
       height: fit-content;
       border-radius: 10px;
       min-height: 150px;
       background-color: #fff;
       padding: 20px 40px 0 40px;
     }
  }
}

.containerContentUpper {
    z-index: 102;
}

