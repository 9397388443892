.btn {
  display: flex;
  align-items: center;
  height: 40px;
  box-shadow: var(--bg-shadow);
  padding: 0 12px;
  border: solid 1px var(--color-gray-100);
  background-color: white;
  color: var(--color-gray-600);
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;
  border-left: none;

  &.firstChild {
    border-radius: 4px 0 0 4px;
    border-left: solid 1px var(--color-gray-100);
    margin-left: 20px;
  }

  &.lastChild {
    border-radius: 0 4px 4px 0;
    border-right: solid 1px var(--color-gray-100);
  }
}

.icon {
  margin-right: 4px;
  padding: 2px;
}

.disabled {
  opacity: .4;
  cursor: default;
}

.active {
  background-color: var(--color-purple-100-opacity-16);
  color: var(--color-purple-100);
  box-shadow: none;
  border-color: transparent;
}

.iconSmall {
  box-shadow: none;

  .icon {
    margin-right: 0;
  }
}
