.controlsContainer {
  display: flex;
  width: 100%;
  margin-bottom: 24px;
}

.filtersContainer {
  display: flex;
  flex-direction: row;
}

.gridFirst {
  flex: 1;
  margin-right: 12px;
}

.gridLast {
  flex: 1;
  margin-left: 12px;
}

.emptyData {
  height: 80%;
}
