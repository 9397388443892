.datePickerWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 8;
  bottom: 9px;
  color: var(--color-gray-800);
  width: 280px;
}

.datePicker {
  padding: 6px;
  width: 100px;
  height: 34px;
  border: solid 1px var(--color-gray-100);
  border-radius: 4px;
  margin: 0 8px;
  color: var(--primary-text-color);
  cursor: pointer;
}
