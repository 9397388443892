.eventsLegend {
  position: absolute;
  display: flex;
  align-items: center;
  color: var(--color-gray-800);
  right: 24px;
}

.circle {
  display: inline-flex;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 8px 0 16px;
  background-color: var(--color-gray-600);

  &Labs {
    margin: 0;
    z-index: 2;
  }
}

.labsLegend {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 355px;
  color: var(--color-gray-800-opacity-70);
  right: 24px;

  &:after {
    position: absolute;
    content: '';
    width: 90%;
    height: 2px;
    background: linear-gradient(to right, #510d62 0%, #7a2f8c 17%, #c470d8 33%, #535f9c 38%, #535f9c 63%, #d8707c 67%, #a32433 83%, #610913 100%);;
    bottom: 4px;
    left: 14px;
  }

  &Group {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 30px;
    justify-content: space-between;
  }
}

.middleName {
  color: var(--color-gray-800);
}
