.filtersContainer {
  display: flex;
  flex-direction: row;
  min-height: 293px;
}

.grid {
  flex: 1;

  &Middle {
    flex: 1;
    margin-left: 12px;
    margin-right: 12px;
  }
}

.emptyData {
  height: 80%;
}
