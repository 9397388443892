.splitByClasses {
  &Container {
    display: flex;
    align-items: center;
    height: 40px;
    box-shadow: var(--bg-shadow);
    padding: 0 8px;
    margin-left: 16px;
    border: solid 1px var(--color-gray-100);
    border-radius: 4px;

    & label {
      padding: 0;
      height: 21px;
      display: flex;
      align-items: center;

      & h4 {
        margin-left: 8px;
        color: var(--color-gray-600);
        font-weight: 500;
      }
    }
  }
  &Disabled {
    background-color: var(--color-disabled);
  }
}
