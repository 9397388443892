.headerContainer {
    display: flex;
    justify-content: space-between;

}
.headerRight {
    display: flex;
    align-items: center;
}

.headerSearchOffset {
    margin-right: 20px;
}

.headerSearch {
    max-width: 370px;
    width: 100%;
    min-width: 350px;
}

.selectTopClassName {
    max-width: 340px;
    min-width: 200px;
    width: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    & > span {
      text-overflow: ellipsis;
      width: 100%;
      overflow: hidden;
    }
  }
  
