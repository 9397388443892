.btn {
  background-color: var(--color-purple-100);
  border: none;
  border-radius: 4px;
  color: white;
  display: inline-block;
  min-width: 60px;
  min-height: 40px;
  height: 32px;
  padding: 0 16px;
  cursor: pointer;
  transition: background-color .3s ease;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: .02em;
}

.wide {
  min-width: 78px;
  min-height: 40px;
  height: 48px;
  width: 166px;
  font-size: 18px;
}

.small {
  min-width: 80px;
  min-height: 32px;
  height: 32px;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 80px;
  display: flex;
  flex-direction: column;
}

.large {
  height: 48px;
  padding: 14px 10px;
  border-radius: 4px;
}

.default {
  background: var(--color-pirple-light-100);
  color: var(--color-purple-100);
}

.secondary {
  background-color: var(--bg-color);
  color: var(--color-purple-100);
  border: 1px solid #fff;
}

.basic {
  background-color: white;
  color: var(--color-purple-100);
  font-weight: normal;
}

.disabled {
  cursor: default;
}

.loading {
  --first-color: var(--color-purple-100-opacity-16);
  --first-color-alt: var(--color-purple-300);

  position: relative;
  cursor: wait;
  overflow: hidden;

  .btnText {
    position: relative;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 150%;
    height: 100%;
    background: repeating-linear-gradient(60deg,
    transparent,
    transparent 0.75rem,
    var(--first-color-alt) 0.75rem,
    var(--first-color-alt) 1.5rem);
    animation: load 1s infinite linear;
  }
}

/* Loading button animation */
@keyframes load {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-1.75rem);
  }
}
