.wrapper {
    padding: 0 40px;

    .borderContent {
        display: grid;
        grid-template-columns: 32px minmax(232px, 300px) minmax(232px, 330px) 50px;
        grid-gap: 8px;
        align-content: center;
        align-items: center;
        width: 100%;
        height: 48px;
        border-bottom: 1px solid var(--color-gray-200);

        .selected {
            min-width: 232px;
            padding: 0 8px;
            height: 32px;
            border-radius: 4px;
            box-shadow: 0 8px 24px 0 rgba(82, 93, 158, 0.4);
            background-color: var(--color-purple-100);
            font-size: 14px;
            font-weight: 600;
            font-style: normal;
            line-height: 1.14;
            letter-spacing: normal;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            align-content: center;
            color: var(--bg-light-color);

            .overflowText {
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
                display: -webkit-box;
            }
        }

        .dropdown {
            max-width: 330px;
            flex: 1;

            .selectedOption {
                cursor: pointer;
                //width: 330px;
                height: 32px;
                padding: 4px 8px;
                border-radius: 4px;
                box-shadow: 0 2px 4px 0 rgba(49, 50, 54, 0.08);
                border: solid 1px var(--color-gray-100);
                background-color: #fff;
                align-items: center;
                align-content: center;
                display: flex;
                position: relative;
                justify-content: space-between;


                .contentDropdownHidden {
                    display: none;
                }

                .contentDropdown {
                    z-index: 1000;
                    margin-top: 100px;
                    background: #fff;
                    display: flex;
                    flex-direction: column;
                    position: absolute;
                    height: fit-content;
                    width: 330px;
                    box-shadow: 0 2px 4px 0 rgba(49, 50, 54, 0.08);
                    right: 1px;
                    border-radius: 6px;

                    .hover:hover {
                        background-color: var(--color-purple-100);
                        color: #fff;
                        font-size: 14px;
                        font-weight: 600;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.14;
                        letter-spacing: normal;
                        border-radius: 6px;

                    }

                    .hover {
                        width: 100%;
                        padding: 8px 6px;
                        font-size: 14px;
                        font-weight: 500;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.14;
                        letter-spacing: normal;
                        color: var(--color-gray-400);
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 1;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        display: block;
                        display: -webkit-box;
                    }
                }

                .notSelected {
                    font-size: 14px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.14;
                    letter-spacing: normal;
                    color: var(--color-gray-400);
                    overflow: hidden;
                    max-width: 232px;

                }

                .selectedSearch {
                    font-size: 14px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.14;
                    letter-spacing: normal;
                    color: var(--color-gray-800);
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 1;
                    max-width: 220px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    display: -webkit-box;
                }

                .dropdownImg {
                    width: 24px;
                }
            }


        }

        .remove {
            margin: 0 4px;
            height: 24px;
            width: 24px;
            border: none;
            cursor: pointer;
            background: url("../../../../static/images/remove.webp") no-repeat;
            background-size: 24px 24px;
            flex-shrink: 0;
        }

        .radioWrapper {
          display: flex;
          justify-content: center;
        }

        .radioWrapper label {
            padding: 0;
        }

        .radioWrapper div {
            margin: 0;
        }
    }
}
