:root {
  --color-white: #ffffff;
  --color-pirple-light-100: #e2e4ee;
  --color-purple-100: #535f9c;
  --color-purple-300: #2d3977;
  --color-purple-700: #14204f;
  --color-purple-100-opacity-50: rgba(83, 95, 156, 0.50);
  --color-purple-100-opacity-16: rgba(83, 95, 156, 0.16);

  --color-red-400: #da3634;
  --color-red-400-opacity-16: rgba(218, 54, 52, 0.16);

  --color-yellow-400: #f7b500;
  --color-yellow-400-opacity-16: rgba(247, 181, 0, 0.16);

  --color-green-500: #428d68;
  --color-shadow-gradiend: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));

  --color-gray-100: #ebedf5;
  --color-gray-200: #e3e3e3;
  --color-gray-300: #dfdfdf;
  --color-gray-310: #afb0b3;
  --color-gray-350: rgba(151, 151, 151, 0.30);
  --color-gray-400: #838486;
  --color-gray-600: #5a5b5e;
  --color-gray-800: #313236;
  --color-gray-300-opacity-35: rgba(223, 223, 223, 0.36);
  --color-gray-800-opacity-70: rgba(49, 50, 54, 0.70);
  --color-gray-800-opacity-20: rgba(49, 50, 54, 0.2);

  --color-disabled: rgba(239, 239, 239, 0.3);
  --color-black-opacity-10: rgba(0, 0, 0, 0.1);

  --bg-color: #e3e5ef;
  --bg-light-color: #f2f4fa;
  --bg-header-color: var(--color-purple-300);

  --border-radius: 5px;

  --primary-text-color: var(--color-gray-800);
  --font-family: Inter, 'Roboto', 'Helvetica', sans-serif;
  --base-font: 400 14px/1.14em var(--font-family);

  --bg-shadow: 0 2px 4px 0 rgba(49, 50, 54, 0.08);
  --bg-shadow-purple: 0 8px 24px 0 rgba(83, 95, 156, 0.4);
  --bg-shadow-purple-dark: 0 8px 24px 0 rgba(45, 57, 119, 0.4);

  --grid-row-gap: 32px;
  --grid-col-gap: 30px;
}
