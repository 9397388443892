.mainWrapper {
    display: flex;
    height: auto;
    padding: 0 8px 16px 8px;
    overflow: auto;
    color: var(--color-gray-800);
    justify-content: center;
  
    .columnWrapper {
      display: flex;
      align-items: center;
      flex-direction: column;
  
      .section {
        margin-top: -15px;
      }
  
      .buttonText {
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: normal;
      }
  
      .btn {
        width: 233px;
      }
    }
  }
  