.logo {
  width: 32px;
  height: 32px;
  cursor: pointer;
  pointer-events: auto;

  &Container {
    width: 100%;
    position: absolute;
    top: 0;
    padding: 0 0 7px 10px;
    pointer-events: none;
    z-index: 21;
    max-width: 1400px;
    min-width: 1366px;
    margin-top: 14px;
    margin-left: 50%;
    margin-right: auto;
    transform: translateX(-50%);
  }

  &Text {
    margin-left: 16px;
  }
}
