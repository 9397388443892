/* F O N T S */

@font-face {
    font-family: 'Inter';
    src: url('../fonts/inter-bold-webfont.woff2') format('woff2'),
    url('../fonts/inter-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/inter-semibold-webfont.woff2') format('woff2'),
    url('../fonts/inter-semibold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/inter-medium-webfont.woff2') format('woff2'),
    url('../fonts/inter-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/inter-regular-webfont.woff2') format('woff2'),
    url('../fonts/inter-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    text-rendering: optimizeLegibility;
}
