.timeline {
  background: white;
  position: fixed;
  bottom: 0;
  height: 50px;
  z-index: 6;
  width: inherit;
  box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.2);
}

.emptyChartContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: white;
  width: 100%;
  height: 200px;
  border-radius: 4px;
  margin-bottom: 20px;
  box-shadow: var(--bg-shadow);
}
