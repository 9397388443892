.reportClasses {
  display: block;
  margin-top: 13px;
  width: 300px;
  z-index: 2;
  background-color: white;

  & p {
    margin-top: 8px;
  }
}

.reportClassWrapper {
  background-color: var(--bg-light-color);
  border-radius: 4px;
  margin: 4px 0;
  padding: 12px 16px;
  height: 48px;
  width: 300px;

  &:hover {
    cursor: pointer;
  }

  .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.selectedReportClass {
  background-color: var(--color-green-500);
  background-image: var(--color-shadow-gradiend);
  transition: background-color 0.2s;

  & h4 {
    color: white;
  }
}
