.cases {
  display: flex;
  flex-direction: row;

  &Wrapper {
    display: flex;
    flex-direction: column;
  }
}

.chartView {
  flex: 1 1;
}

.casesColumn {
  display: flex;
  flex-direction: column;
  flex: 1.1;
}
