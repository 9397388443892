@import '../../../static/styles/_mixins.css';

.category {
  color: var(--color-gray-600);
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 4px;

  &List {
    display: flex;
    flex-wrap: wrap;
  }

  &Block {
    margin-bottom: 15px;
  }
}

.chips {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-purple-100-opacity-16);
  padding: 10px 0 10px 14px;
  border-radius: 25px;
  margin: 4px 4px 4px 0;
  cursor: default;

  &Name {
    @mixin textOverflow;
    font-weight: 500;
    color: var(--color-purple-100);
    margin-right: 8px;
    line-height: 18px;
    max-width: 175px;
    text-transform: capitalize;
  }

  &FullName {
    overflow: initial;
    text-overflow: initial;
    max-width: fit-content;
  }

  &Offset {
    padding-right: 10px;
  }
}

.btnClose {
  border: none;
  background: none;
  height: 18px;
  width: 25px;
  text-align: left;
}

.icon {
  width: 9px;
  height: 9px;
  color: var(--color-purple-100);
  cursor: pointer;
  opacity: .9;

  &CloseAll {
    color: var(--color-gray-310);
    margin-left: 4px;
  }
}
