.wrapper {
  display: flex;
  flex-direction: row;
  height: auto;
  padding: 7px 8px 16px 8px;
  overflow: auto;
  color: var(--color-gray-800);

  .columnWrapper {
    min-width: 350px;

    &.middleColumn {
      flex-grow: 1;
    }
    .title {
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      margin-bottom: 24px;
    }
    .text {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
    }
    .textGrey {
      color: rgba(49, 50, 54, 0.61);
    }
    .middleColumnWrapper {
      display: flex;
      flex-direction: row;
      &.firstRow {
        margin-bottom: 15px;
      }
      .titleColumn {
        width: 117px;
      }
    }
  }
}
