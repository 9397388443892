.countLine {
  display: flex;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background-color: var(--color-gray-800-opacity-20);
  box-shadow: 0 0 1px var(--color-gray-100);
  position: relative;
  margin-top: 4px;
  flex: 3;

  &Percentage {
    height: 4px;
    border-radius: 2px;
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;
  }
}
