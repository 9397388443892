.wrapper {
  background-color: var(--bg-light-color);
  position: relative;
  min-height: 300px;
  height: auto;
  border-radius: 12px;
  box-shadow: 0 24px 32px 0 rgba(76, 80, 100, 0.16);
  padding: 4px;
  margin-bottom: 28px;

  &LeftOffset {
    margin-left: 28px;
  }
  &Editable {
    background-color: var(--color-purple-100);
    transition: background-color 0.15s ease-in;
    position: relative;
    z-index: 22;
  }
}

.header {
  display: flex;
  align-items: center;
  padding: 0 36px;
  height: 56px;
  white-space: nowrap;
  &Editable {
    color: var(--bg-light-color);
  }
}

.headerWithButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 36px;
  height: 56px;
  white-space: nowrap;

  .title {
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: var(--color-purple-100);
  }

  .button {
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    background: none;
    border: none;
    cursor: pointer;
    color: var(--color-purple-100);
  }
}

.content {
  background-color: white;
  border-radius: 10px;
  min-height: 51px;
  height: calc(100% - 56px);
  padding: 24px 34px 8px;
}
