@import url('_fonts.css');
@import url('_variables.css');
@import url('_typography.css');
@import url('_mixins.css');

:root {
  --content-border-radius: var(--border-radius);
  --content-padding: 32px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}

body {
  margin: 0;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

html {
  font-size: 62.5%;
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  background: var(--bg-color);
}

#root {
  display: flex;
  flex-direction: column;
  min-width: 960px;
}

.page-content {
  width: 100%;
  height: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: column;

  &--wide {
    width: 100%;
    margin: 0;
    padding: 0 var(--grid-row-gap) 28px;
  }

}

.content-block {
  border-radius: var(--content-border-radius);
  padding: var(--content-padding);

  &--no-padding {
    padding: 0;
  }
}

.grid {
  display: grid;
  height: 100%;
  grid-template-columns: 1fr;
  grid-column-gap: var(--grid-col-gap);
  grid-row-gap: var(--grid-row-gap);
}

.grid-col-full {
  grid-column: 1/-1;
}

.tooltip {
  background-color: white;
  font-size: 14px;
  color: var(--color-gray-800);
  padding-left: 4px;
  max-width: 210px;
  white-space: normal;

  &-date {
    opacity: 0.7;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    bottom: -16px;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-color: white transparent transparent transparent;
    border-style: solid;
    border-width: 8px;
    z-index: 20;
  }
}

.primaryTextEmpty {
  font-size: 16px;
  color: var(--color-gray-400);
}

.bold {
  font-weight: 700;
}


/* webkit scrollbar styles */
::-webkit-scrollbar {
  width: .5rem;
  height: .5rem;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

::-webkit-scrollbar-thumb {
  background: var(--color-gray-310);
  border: 1.3rem none var(--bg-color);
  border-radius: 5rem;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--color-gray-400);
}

::-webkit-scrollbar-thumb:active {
  background: var(--color-gray-400);
}

::-webkit-scrollbar-track {
  background: transparent;
  border: 1.3rem none transparent;
  border-radius: 5rem;
}

::-webkit-scrollbar-track:hover {
  background: transparent;
}

::-webkit-scrollbar-track:active {
  background: transparent;
}

::-webkit-scrollbar-corner {
  background: transparent;
}
