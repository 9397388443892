@import '../../static/styles/_mixins.css';

.wrapper {
  position: relative;
  padding-bottom: 30px;
  width: 100%;
  height: fit-content;
}

.filterDataContainer {
  max-height: 263px;
  overflow: auto;
  cursor: pointer;
}

.withoutScroll {
  overflow: hidden;
}

.whileDownload {
  max-height: fit-content;
  overflow: visible;
}

.filtersWithPercentage {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 40px;
  background-color: var(--bg-light-color);
  border-radius: 4px;
  margin-bottom: 4px;
  padding: 0 8px;

  & h4 {
    @mixin textOverflow;
    flex: 1.5;
    padding-right: 12px;
    text-transform: capitalize;
  }
}

.numberCasesContainer {
  display: flex;
  position: absolute;
  height: 100%;
  padding: 0 8px;
  color: var(--color-gray-400);
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  pointer-events: none;

  .title {
    @mixin textOverflow;
    font-weight: bold;
    font-size: 12px;
    flex: 1.5;
    align-self: flex-end;
    padding-right: 12px;
  }

  .interval {
    display: flex;
    justify-content: space-between;
    position: relative;
    flex: 3;
  }

  .intervalText {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    left: -49%;
  }

  .intervalPoint {
    width: 25%;
    position: relative;
  }

  .intervalPoint:before {
    position: absolute;
    content: '';
    width: 1px;
    bottom: 28px;
    top: 0;
    background-color: var(--color-black-opacity-10);
  }
}

.filterSelected {
  background-color: var(--color-purple-100);
  background-image: var(--color-shadow-gradiend);
  transition: background-color 0.2s;
  box-shadow: 0 8px 24px 0 rgba(82, 93, 158, 0.7);

  & h4 {
    color: white;
  }
}

.emptyData {
  justify-content: flex-start;
}
