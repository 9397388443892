.saveImageBtn {
  position: absolute;
  top: 78px;
  right: 40px;
  display: flex;
  align-items: center;
  height: 40px;
  box-shadow: var(--bg-shadow);
  padding: 0 8px;
  margin-left: 16px;
  border: solid 1px var(--color-gray-100);
  border-radius: 4px;
  background-color: white;
  color: var(--color-gray-600);
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;
}

.icon {
  margin-right: 4px;
  padding: 2px;
}

.loadingIcon {
  width: 24px;
  height: 24px;
  color: var(--color-gray-300);
}

.disabled {
  opacity: .4;
  cursor: default;
}

.iconSmall {
  box-shadow: none;

  .icon {
    margin-right: 0;
  }
}
