@import '../../../static/styles/_mixins.css';

.icon {
  margin-right: 4px;
}

.title {
  white-space: nowrap;
}

.btn {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: white;
  opacity: 0.6;
  line-height: 1.14;
  font-size: 14px;
  min-width: 78px;
  min-height: 40px;
  height: 40px;
  width: auto;
  padding: 0 12px;
  transition: background-color .3s ease;
  font-weight: bold;
  letter-spacing: .02em;
  margin: 0 10px;
  position: relative;

  &Active {
    background-color: var(--color-purple-100);
    opacity: 1;
    box-shadow: var(--bg-shadow-purple);
  }

  &:hover {
    background-color: var(--color-purple-100);
    box-shadow: var(--bg-shadow-purple);
  }
}

.count {
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: var(--color-purple-700);
  top: -9px;
  right: -9px;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropIcon {
  color: white;
  margin-left: 8px;
  width: 16px;
  height: 16px;
  min-width: 16px;
}

.disabled {
  @mixin disableButton;
}
