@import '../../../static/styles/_mixins.css';

.selectContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 140px;
  margin-right: 16px;
  width: fit-content;

  &Dark {
    margin: 0 10px;

    .selectTop {
      border: none;
      color: white;
      background-color: var(--color-purple-100);
      opacity: 1;
      box-shadow: var(--bg-shadow-purple);
      font-weight: 700;
      max-width: 200px;
      min-width: 140px;
      width: 100%;

      & span {
        @mixin textOverflow;
      }
    }

    .selectTopDisabled {
      @mixin disableButton;
    }

    .icon {
      color: white;
      margin-left: 8px;
      width: 16px;
      height: 16px;
      min-width: 16px;
    }
  }
}

.selectTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 200px;
  width: fit-content;
  height: 40px;
  border-radius: 4px;
  box-shadow: var(--bg-shadow);
  border: solid 1px var(--color-gray-100);
  padding: 0 12px;
  font-weight: 500;
  color: var(--color-gray-800);
  cursor: pointer;
  user-select: none;
  white-space: nowrap;


  &.selectTopDisabled {
    @mixin disableButton;
    background: #fff;
  }

}

.long {
  width: 100%;
  min-width: 232px;
  font-weight: 500;
  max-width: 232px;
  padding: 0 8px;
  height: 32px;
  overflow: hidden;
}

.selectList {
  position: absolute;
  list-style-type: none;
  z-index: 100;
  background: white;
  width: auto;
  box-shadow: var(--bg-shadow);
  border: solid 1px var(--color-gray-100);
  border-radius: 4px;
  white-space: nowrap;
  padding: 8px 0;
  margin-top: 4px;
  max-height: 500px;
  overflow: auto;
  min-width: 100%;

  &Fetching {
    overflow: hidden;
  }

  & > li {
    cursor: pointer;
  }
}

.icon {
  width: 16px;
  height: 16px;
  color: var(--color-gray-800);
}

.selectAllLabel {
  color: var(--color-gray-400);
  font-weight: 500;
}

.selectName {
  font-weight: 500;
  padding: 12px 24px 12px 10px;
}
