.caseVolumeContainer {
  display: flex;
  flex-direction: row;
  height: 315px;
}

.caseVolumeData {
  display: flex;
  flex-direction: row;
  height: 315px;
}

.caseContentCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 3;
}
