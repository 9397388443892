.inner {
  padding: 40px;

  .title {
    margin: 7px 0 16px;
    padding: 0;
    overflow-wrap: break-word;
  }

  .text {
    margin: 0;
    font-size: 16px;
    line-height: 1.25;
    overflow-wrap: break-word;
    max-height: 40vh;
    overflow: auto;
  }

  .footer {
    margin: 36px 0 0 0;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    .action {
      margin: 0 0 0 8px;
      &Cancel {
        width: 140px;
      }
      &Submit {
        width: 160px;
      }
    }
  }
}
