.tag {
  position: relative;
  padding: 4px 8px;
  border: 0;
  border-radius: 2px;
  overflow: hidden;

  &:after {
     content: '';
     position: absolute;
     left:0;
     right: 0;
     top:0;
     bottom: 0;
     opacity: .2;
     background: var(--bg-color);
   }
}
