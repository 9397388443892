@import '../../../static/styles/_mixins.css';

:root {
  --radio-size: 1em;
  --radio-border-width: 1px;
  --radio-border-width-bold: 2px;
  --radio-color: #dfe2f2;
  --radio-checked-color: #333955;
  --radio-focus-color: #5f72be;
  --radio-transition: .3s;
}

label {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 12px 24px 12px 8px;
  user-select: none;
  cursor: pointer;

  & span {
    @mixin textOverflow;
  }
}

.customRadio {
  position: absolute;
  appearance: auto;
  z-index: -1;
  opacity: 0;
}

.radioInput:checked ~ .radioLabel > .radioMarker::before {
  background-color: var(--radio-checked-color);
}

.radioInput:disabled ~ .radioLabel {
  opacity: .5;
}

.radioInput:focus ~ .radioLabel {
  --radio-border-width: var(--radio-border-width-bold);
  --radio-color: var(--radio-focus-color);
  --radio-checked-color: var(--radio-focus-color);
}

/*** Markup ***/

.radioInput {
  position: absolute;
  opacity: 0;
  z-index: -1;
  margin: 0;
}

.radioLabel {
  display: flex;
  align-items: center;
  width: auto;

  cursor: pointer;
}

.radioMarker {
  position: relative;

  width: var(--radio-size);
  height: var(--radio-size);

  margin-right: 1rem;

  border-radius: 50%;
  border: var(--radio-border-width) solid var(--radio-color);
  background-color: transparent;
  outline: none;

  transition: .1s border;

  &::before {
    content: '';

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: calc(var(--radio-size) / 2);
    height: calc(var(--radio-size) / 2);

    border-radius: 50%;
    background-color: transparent;

    transition: var(--radio-transition) background-color;
  }
}
