.mainWrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    height: calc(100% - 190px);
    overflow-x: auto;
}

.textWrapper {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #313236;
}

.selection {
    position: relative;
    cursor: pointer;
  transition: background-color 0.15s ease-in-out;

    .closeButton {
        cursor: pointer;
        display: none;
        position: absolute;
        right: -24px;
        top: -1px;
        width: 24px;
        height: 24px;
        box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.2);
        border: solid 1px #dfdfdf;
        background-color: #fff;
        padding: 1px 7px;
        border-radius: 4px;
        color: #da3634;
        .closeIcon {
            width: 8px;
            padding: 3px 0;
        }
    }

    &:hover .closeButton {
        display: block;
    }
}



.selection0 {
    background-color: rgba(247,181,0, 0.3);
    &.hover {
        background-color: #f7b500;
    }
}

.selection1 {
    background-color: rgba(224,32,32, 0.3);
    &.hover {
        background-color: #e02020;
        color: white;
    }
}

.selection2 {
    background-color: rgba(98,54,255, 0.3);
    &.hover {
        background-color: #6236ff;
    }
}

.selection3 {
    background-color: rgba(182,32,224, 0.3);
    &.hover {
        background-color: #b620e0;
    }
}

.selection4 {
    background-color: rgba(109,212,0, 0.3);
    &.hover {
        background-color: #6dd400;
    }
}

.selection5 {
    background-color: rgba(68,215,182, 0.3);
    &.hover {
        background-color: #44D7B6 ;
    }
}

.selection6 {
    background-color: rgba(50,197,255, 0.3);
    &.hover {
        background-color: #32C5FF ;
    }
}


.selection7 {
    background-color: rgba(0,145,255, 0.3);
    &.hover {
        background-color: #0091FF ;
    }
}

.selection8 {
    background-color: rgba(109,114,120, 0.3);
    &.hover {
        background-color: #6D7278 ;
    }
}


.selection9 {
    background-color: rgba(255,214,10, 0.3);
    &.hover {
        background-color: #FFD60A ;
    }
}


.selection10 {
    background-color: rgba(204,102,136, 0.3);
    &.hover {
        background-color: #CC6688 ;
    }
}


.selection11 {
    background-color: rgba(43,56,123, 0.3);
    &.hover {
        background-color: #2B387B;
        color: white;
    }
}
