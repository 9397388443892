.contentModalWindow {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    overflow-y: hidden;
    overflow-x: hidden;
    max-height: 472px;

.twoSidesContent {
    width: 100%;
    max-width: 624px;
    height: 100%;
    min-height: 472px;
    flex-direction: row;
    display: flex;
    box-shadow: 0 32px 40px 0 rgba(0, 0, 0, 0.12);

.leftContent {
    border-right: 1px solid #d5d5d5;
    align-items: center;
    padding: 20px 40px 0 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 472px;
    overflow-x: hidden;
    overflow-y: auto;

.search {
    width: 232px;
    height: 40px;
    box-shadow: 0 2px 4px 0 rgba(49, 50, 54, 0.08);
    background-color: #fff;
}

&List {
     max-width: 310px;
     margin-top: 16px;
     display: flex;
     justify-content: center;
     flex-direction: column;
     align-content: center;
     align-items: center;
     width:100%;

.list {
    cursor: pointer;
    list-style-type: none;
    height: 40px;
    border-radius: 4px;
    background-color: var(--bg-light-color);
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 4px;
    max-width: 232px;
    justify-content: flex-start;

.item {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: var(--color-gray-800);
    padding-left: 8px;
}
}

.active {
    background-color: var(--color-purple-100);
    cursor: pointer;
    list-style-type: none;
    height: 40px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 4px;
    max-width: 232px;
    justify-content: flex-start;

.item {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: #fff;
    padding-left: 8px;
}
}
}
}

.rightContent {
    width: 100%;
    max-width: 313px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px 40px 0 40px;
    height: 472px;
    overflow-x: hidden;
    overflow-y: auto;

.searchRight {
    width: 232px;
    height: 40px;
    box-shadow: 0 2px 4px 0 rgba(49, 50, 54, 0.08);
    background-color: #fff;
}

&List {
     max-width: 310px;
     margin-top: 16px;
     display: flex;
     justify-content: center;
     flex-direction: column;
     align-content: center;
     align-items: center;
     width:100%;

.listRight {
    position: relative;
    cursor: default;
    list-style-type: none;
    height: 40px;
    border-radius: 4px;
    background-color: #f2f4fa;
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 4px;
    max-width: 232px;
    justify-content: space-between;

.plus {
    display: none;
    background-color: var(--color-purple-100);
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    align-content: center;
    border: none;
    border-radius: 4px;
    margin-right: 8px;
    background-image: url("../../../static/images/plus.webp");
    background-size: 24px 24px;
}

.itemRight {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: var(--color-gray-800);
    padding-left: 8px;
    opacity: 0.8;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 196px;
    display: block;
    display: -webkit-box;
}

.show {
    position: absolute;
    z-index: 10000;
    margin-top: 125px;
    margin-left: 84px;
    width: 139px;
    height: 96px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(49, 50, 54, 0.08);
    border: solid 1px var(--color-gray-100);
    flex-direction: column;
    background-color: #fff;

.contentShow {
    flex-direction: column;
    display:flex;

.showButton {
    padding-left: 8px;
    width: 135px;
    height: 30px;
    cursor: pointer;
    background-color: #fff;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: #838486;
    border: none;
}

.showButton:hover {
    color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(49, 50, 54, 0.08);
    background-color:  var(--color-purple-100);
}

}


}
}

.listRight:hover {
.plus {
    cursor: pointer;
    display: flex;
}
.itemRight {
    opacity: 1;
}
}
}
}
}

.scrollRight {
    height: 472px;
    overflow-y: auto;
    overflow-x: auto;
    width: 100%;
    display: flex;

.rightSideModal {
    display: flex;
    margin: 24px 0 60px;
    width: 100%;
    flex-direction: column;

.content {
    width: 100%;

    .optionWrapper > div:last-child > div {
        border-bottom: none;
    }

.title {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: var(--color-purple-100);
    background-color: var(--bg-light-color);
    height: 32px;
    padding: 8px 40px;
}

    .titleRight {
        color: var(--color-gray-800);
        float: right;
    }

.targetContent {
    margin-bottom: 50px;
.main {
    width: 100%;
    max-width: 544px;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--color-gray-800);
    padding-bottom: 12px;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    border-bottom: 1px solid var(--color-gray-200);
}

}

.second {

.secondText {
    width: 100%;
    max-width: 544px;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--color-gray-800);
    padding-bottom: 12px;
    border-bottom: 1px solid var(--color-gray-200);
    font-size: 12px;
    font-weight: bold;
}

}
}

.comp {
 padding-bottom: 20px;

.compText {
    width: 100%;
    max-width: 544px;
    border-bottom: 1px solid var(--color-gray-200);
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--color-purple-100);
    padding-bottom: 8px;
}

}
}
}
}

.noContent {
    align-content: center;
    align-items: center;
    margin-top: 8px;
    display: flex;
    padding: 12px 40px;

    .warning {
        margin-right: 12px;
        height: 24px;
        width: 24px;
    }

    .warnText {
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: var(--color-gray-800);
    }
}