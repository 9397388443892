.mainWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    height: calc(100% - 80px);
    overflow-x: auto;
    .group {

        .label {
            display: flex;
            flex-direction: row;
            height: 32px;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.14;
            letter-spacing: normal;
            color: #313236;
            padding: 8px 0 0 20px;
            background-color: #f2f4fa;

            .rect {
                width: 16px;
                height: 16px;
                margin: 0 8px 0 0;
                border-radius: 2px;
                box-shadow: 0 2px 4px 0 rgba(49, 50, 54, 0.08);
            }

            .rect0 {
                border: solid 1px #f7b500;
                background-color: #f7b500;
            }

            .rect1 {
                border: solid 1px #e02020;
                background-color: #e02020;
            }

            .rect2 {
                border: solid 1px #6236ff;
                background-color: #6236ff;
            }

            .rect3 {
                border: solid 1px #b620e0;
                background-color: #b620e0;
            }

            .rect4 {
                border: solid 1px #6dd400;
                background-color: #6dd400;
            }

            .rect5 {
                border: solid 1px #44D7B6 ;
                background-color: #44D7B6 ;
            }

            .rect6 {
                border: solid 1px #32C5FF ;
                background-color: #32C5FF ;
            }

            .rect7 {
                border: solid 1px #0091FF ;
                background-color: #0091FF ;
            }

            .rect8 {
                border: solid 1px #6D7278 ;
                background-color: #6D7278 ;
            }

            .rect9 {
                border: solid 1px #FFD60A ;
                background-color: #FFD60A ;
            }

            .rect10 {
                border: solid 1px #CC6688 ;
                background-color: #CC6688 ;
            }

            .rect11 {
                border: solid 1px #2B387B;
                background-color: #2B387B;
            }
        }

        .text {
            position: relative;
            height: 48px;
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.14;
            letter-spacing: normal;
            color: #313236;
            padding: 14px 9px 12px 20px;
            cursor: pointer;
            .textText {
                width: 252px;
            }

            .prefName {
              align-self: end;
            }

            .closeButton {
                cursor: pointer;
                display: none;
                position: absolute;
                right: 12px;
                top: 12px;
                width: 24px;
                height: 24px;
                padding: 3px 0 3px 7px;
                background-color: #edf0f7;
                border: 1px solid #edf0f7;
                border-radius: 4px;
                color: #da3634;
                .closeIcon {
                    width: 8px;
                }
            }
            &:hover {
                .closeButton {
                    display: block;
                }
            }


        }
        .hover {
            background-color: #e2e4ee;
        }
    }
}

.textWrapper {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #313236;
}
