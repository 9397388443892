.containerModal {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 100;
  opacity: 0.5;
  background-color: var(--color-gray-200);
  overflow-y: hidden;
  display: flex;
  justify-content: center;
}

.containerContent {
  width: 100%;
  height: fit-content;
    z-index: 102;
  margin-top: 140px;
  position: fixed;
  max-height: 532px;
  max-width: 1440px;
  min-width: 1366px;

  left: 50%;
  transform: translateX(-50%);

  &Wrapper{
    height: fit-content;
    max-height: calc(100vh - 155px);
    margin: 0 16px;
    border-radius: 12px;
    background: var(--color-purple-100);
    display: flex;
    flex-direction: column;
    box-shadow: 0 24px 32px 0 rgba(76, 80, 100, 0.16);

    &Header {
      margin: 0 40px;
      display: flex;
      z-index: 102;
      align-content: center;
      align-items: center;
      min-height: 56px;

      &Text {
       display: flex;
       align-content: center;
       align-items: flex-end;
       white-space: nowrap;
       overflow: hidden;

        .title {
          font-size: 24px;
          font-weight: bold;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          color: #fff;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .arrowLeft {
          color: var(--primary-text-color);
          cursor: pointer;
        }
      }

      &Buttons {
        display: flex;
        margin-right: 16px;
      }
    }

    &Items {
       margin: 0 4px 4px 4px;
       height: fit-content;
       border-radius: 10px;
       min-height: 150px;
       background-color: #fff;
       display: flex;
       flex-direction: row;


        .leftWrapper {
            min-width: 470px;
            padding: 20px 20px 20px 36px;
            box-shadow: 0 32px 40px 0 rgba(0, 0, 0, 0.12);

            .search {
                width: 414px;
            }

            .leftTitleWrapper {
                display: flex;
                flex-direction: row;
                padding: 20px;
                font-size: 12px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.33;
                letter-spacing: normal;
                color: #838486;

                .leftTitleFirst {
                    width: 252px;
                }
                .leftTitleSecond {

                }
            }
        }

        .rightWrapper {
           padding: 20px 25px;
            width: calc(100% - 472px);

            .rightHeaderWrapper {
              display: grid;
              grid-template-columns: 1fr 1fr;
              grid-column-gap: 10px;
                .rightHeaderColumn {
                    .rightHeaderItem {
                        display: flex;
                        flex-direction: row;
                        height: 40px;
                        align-items: center;

                        .rightHeaderLabel {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            width: 144px;
                            opacity: 0.6;
                            font-size: 14px;
                            font-weight: 500;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.14;
                            letter-spacing: normal;
                            color: #313236;

                            .line {
                                flex-grow: 1;
                                height: 1px;
                                border: dashed 1px #afb0b3;
                                margin: 0 10px 0 5px;
                            }
                        }

                        .rightHeaderChildren {
                            font-size: 14px;
                            font-weight: 500;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.14;
                            letter-spacing: normal;
                            color: #313236;
                            text-overflow: ellipsis;
                        }

                        .red {
                            color: #da3634;
                        }
                    }
                }
            }

            .rightTitle {
                font-size: 18px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.33;
                letter-spacing: normal;
                color: #313236;
                padding: 24px 0 20px 0;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
     }
  }
}
