.react-toggle--checked .react-toggle-thumb {
  left: 14px;
}

.react-toggle-track {
  width: 32px;
  height: 20px;
  background-color: var(--color-gray-310);
  box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.2);
}

.react-toggle-thumb {
  width: 16px;
  height: 16px;
  top: 2px;
  left: 2px;
  border: none;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb,
.react-toggle--focus .react-toggle-thumb {
  box-shadow: none;
}

.react-toggle--checked .react-toggle-track {
  background-color: var(--color-purple-100);
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: var(--color-gray-310);
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: var(--color-purple-100);
}
