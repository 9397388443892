.pieChart {
  position: relative;
  min-height: 293px;
}

.sumCases {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translateX(-50%);
  color: var(--color-gray-800);
  font-size: 28px;
  font-weight: bold;

  &Secondary {
    color: var(--color-gray-600);
    font-size: 14px;
    font-weight: normal;
    margin-top: 10px;
  }
}

.legend {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-15%);
  background-color: white;
  padding: 0 90px;
  min-height: 52px;

  &Control {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 12px;
    height: 48px;
    max-width: 120px;
    border-radius: 4px;
    background-color: var(--bg-light-color);
    margin: 2px;
    cursor: pointer;
    color: var(--color-gray-800);
    user-select: none;
  }

  &ControlActive {
    color: white;
    background-image: var(--color-shadow-gradiend);
  }

  &Line {
    width: 4px;
    height: 32px;
    border-radius: 2px;
    background-color: gray;
    margin: 0 8px;
  }

  &Text {
    display: flex;
    flex-direction: column;
  }

  &TextName {
    font-weight: bold;
    line-height: 18px;
    white-space: nowrap;
  }

  &TextCount {
    white-space: nowrap;
  }
}
