@import '../../../static/styles/_mixins.css';

.selectContainer {
  position: relative;
  width: 99px;
  margin-right: 16px;
}

.selectList {
  padding: 12px;
  height: auto;
  max-height: 500px;
  width: 519px;
  overflow: auto;

  &Container {
    display: flex;
    position: absolute;
    list-style-type: none;
    background: white;
    width: 519px;
    min-height: 100px;
    box-shadow: var(--bg-shadow);
    border: solid 1px var(--color-gray-100);
    border-radius: 4px;
    white-space: nowrap;
    margin-top: 4px;
    right: -3px;
    z-index: 100;
  }
  &Clear {
    position: absolute;
    right: 0;
    top: 0;
    &Text {
      border-bottom: 1px solid var(--color-purple-100);
      transition: border-color 0.15s ease-in-out  ;
    }
    &:hover .selectListClearText {
      border-bottom: 1px solid transparent;
    }
  }
}

.containedButton {
  right: 0;
}

