.case {
  &HeaderContainer {
    display: flex;
    align-items: center;
  }

  &Title {
    font-size: 24px;
    font-weight: bold;
    color: var(--primary-text-color);
  }

  &ContentContainer {
    display: flex;
    flex-direction: row;

    h2 {
      margin-bottom: 16px;
    }
  }
}

.mainInfo {
  color: var(--primary-text-color);
  flex-basis: 650px;
  margin-right: 30px;
  margin-bottom: 20px;

  .primaryText {
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    font-size: 16px;
    padding: 4px 0;
  }
}

.additionalInfo {
  font-size: 16px;
  min-width: 350px;

  .secondaryText {
    font-size: 14px;
    line-height: 1.5em;
    text-transform: capitalize;

    &:last-child {
      margin-bottom: 20px;
    }
  }
}

.title {
  font-weight: bold;
  margin-bottom: 16px;
  font-size: 18px;
  text-transform: capitalize;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 40px;
  height: 40px;
  cursor: pointer;
  margin-right: 16px;
  border-radius: 4px;

  &ArrowLeft {
    color: var(--primary-text-color);
    cursor: pointer;
  }
}

.caseContentCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15rem;
}

.toggleTextBtn {
  color: var(--color-purple-100);
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px;
  margin: 6px 0;
}

.emptyData {
  justify-content: flex-start;
}
