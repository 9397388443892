.searchContainer {
  position: relative;
  border: solid 1px var(--color-gray-100);
  border-radius: 4px;

  &Collapsed {
    cursor: pointer;
    background-color: var(--bg-light-color);
    border: solid 1px transparent;

    .searchInput {
      border: none;
      box-shadow: none;
      background-color: var(--bg-light-color);
      width: 0;
      padding: 0 4px 0 36px;
      cursor: pointer;
    }

    .iconSearch {
      color: var(--color-gray-800);
    }
  }
}

.searchInput {
  height: 32px;
  width: 232px;
  padding: 0 8px 0 40px;
  border-radius: 4px;
  box-shadow: var(--bg-shadow);
  border: none;
  -webkit-appearance: none;
  transition: all .3s;
}

.searchInput::placeholder {
  color: var(--color-gray-400);
}

.iconSearch {
  position: absolute;
  color: var(--color-gray-400);
  width: 32px;
  height: 32px;
  padding: 4px;
  left: 0;
}

input[type=search]::-ms-clear,
input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}
