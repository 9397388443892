.logo {
  width: 184px;
  height: 80px;
  margin: 30px auto 14vh auto;
}

.info {
  display: flex;
  flex-direction: column;
  text-align: right;
  position: absolute;
  top: 24px;
  right: 32px;
  color: var(--bg-light-color);
  font-size: 12px;
  max-width: 242px;
  opacity: 0.6;

  & :first-child {
    margin-bottom: 8px;
  }
}

.welcomeBlock {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--bg-light-color);
  width: 700px;
  height: 56vh;
  margin: 0 auto;
  border-radius: 12px;
  box-shadow: 0 24px 32px 0 rgba(76, 80, 100, 0.16);
  padding: 40px;

  &Title {
    font-size: 24px;
    font-weight: bold;
    color: var(--color-gray-800);
    margin-top: 8px;
  }

  &Subtitle {
    font-size: 16px;
    color: var(--color-gray-600);
    margin-top: 16px;
  }
}

.button {
  margin-top: auto;
}

.form {
  width: 60%;
  margin-top: auto;
}

.field {
  position: relative;
  margin-bottom: 30px;
}

.input {
  height: 40px;
  width: 100%;
  padding: 0 8px;
  border-radius: 4px;
  box-shadow: var(--bg-shadow);
  border: none;
  -webkit-appearance: none;
}

.error {
  border: 1px solid red;

  &Message {
    position: absolute;
    top: 44px;
    color: red;
  }
}

.languageSwitcher {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 2rem 0;
  cursor: pointer;
  text-transform: uppercase;
}

.languageSelect {
  text-transform: capitalize;
}
