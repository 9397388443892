* {
  font-family: var(--font-family);
}

body {
  font: var(--base-font);
  color: var(--primary-text-color);
}

input,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font: inherit;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
}

input[type=number] {
  -moz-appearance: textfield;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

h1, .h1 {
  font-size: 24px;
  font-weight: bold;
  color: var(--primary-text-color);
}

h2, .h2 {
  font-size: 18px;
  font-weight: bold;
  color: var(--primary-text-color);
  text-transform: capitalize;
}

h3, .h3 {
  font-size: 21px;
}

h4 {
  font-size: 14px;
  color: var(--color-gray-800);
}
