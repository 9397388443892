
.leftContent {
    border-right: 1px solid #d5d5d5;
    align-items: center;
    padding: 20px 40px 0 40px;
    width: 100%;
    display: flex;
    flex-direction: column;

.search {
    width: 232px;
    height: 40px;
    box-shadow: 0 2px 4px 0 rgba(49, 50, 54, 0.08);
    background-color: #fff;
}

&ListWrapper {
    width: 100%;
    margin-top: 16px;
    overflow-x: auto;
}

&List {
        max-width: 310px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-content: center;
        align-items: center;
        width: 100%;

        .list {
            cursor: pointer;
            list-style-type: none;
            height: 40px;
            border-radius: 4px;
            background-color: var(--bg-light-color);
            display: flex;
            align-items: center;
            width: 100%;
            margin-bottom: 4px;
            max-width: 232px;
            justify-content: flex-start;

            .item {
                font-size: 14px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.14;
                letter-spacing: normal;
                color: var(--color-gray-800);
                opacity: 0.7;
                padding-left: 8px;
                flex-grow: 1;
            }

            .plus {
                display: none;
                background-color: var(--color-pirple-light-100);
                width: 24px;
                height: 24px;
                justify-content: center;
                align-items: center;
                align-content: center;
                border: none;
                border-radius: 4px;
                margin-right: 8px;
                background-image: url("../../../../static/images/plus-purple.webp");
                background-size: 24px 24px;
            }
        }
    .list:hover {
        background-color: var(--color-pirple-light-100);

        .plus {
            cursor: pointer;
            display: flex;
        }

        .itemRight {
            opacity: 1;
        }
    }


    }
}
