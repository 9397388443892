.paperWidgetWrapper {
  width: 100%;
}

.reportsWrapper {
  background-color: white;
  overflow: hidden;
  height: 70vh;

  &:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    z-index: 9;
  }
}

.paperWidgetContent {
  position: relative;
}

.headerContainer {
  display: flex;
  align-items: center;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 40px;
  height: 40px;
  cursor: pointer;
  margin-right: 16px;
  border-radius: 4px;

  &ArrowLeft {
    color: var(--primary-text-color);
    cursor: pointer;
  }
}

.report, .selectList {
  padding-bottom: 50px;
  background-color: white;
}

.reportRow {
  display: flex;
  flex-direction: row;
}

.withHeight {
  height: 400px;
}

.title {
  margin-bottom: 30px;
}

.ageGroupReport {
  flex: 1;
}

.pdfWrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}

.selectTopClassName {
  min-width: 160px;
}

.globalFilters {
  padding-bottom: 50px;
  background-color: white;
}
