@import '../../../../../../static/styles/_mixins.css';

.filterList {
  max-height: 350px;
  min-height: 350px;
  width: auto;
  overflow: auto;
  transition: all .5s;

  &Container {
    display: flex;
    flex-direction: column;
    margin: 0 6px;
    flex: 1;
    position: relative;
  }

  &Wrapper {
    display: flex;
    flex-direction: row;
    height: auto;
    overflow: auto;
  }

  &Collapsed {
    height: 0;
    margin: 0;
    min-height: 0;
    transition: all .3s;
    overflow-y: hidden;
  }
}

.filterItem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--bg-light-color);
  border-radius: 4px;
  padding: 12px;
  margin-bottom: 4px;
  width: 100%;
  cursor: pointer;
  min-height: 39px;

  &Active {
    background-color: var(--color-purple-100);
    background-image: var(--color-shadow-gradiend);
    transition: background-color 0.2s;
    box-shadow: 0 8px 24px 0 rgba(82, 93, 158, 0.7);

    & .label {
      color: white;
    }
  }
}

.label {
  @mixin textOverflow;
  padding: 0;
  color: var(--color-gray-800);
  max-width: 190px;
  font-weight: 500;
  white-space: nowrap;
}

.title {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.titleHeader {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 4px;
  width: 100%;
  margin-bottom: 8px;

  .titleWrapper {
    display: flex;
    flex-direction: row;
    width: 100%;

    & h4 {
      flex-grow: 1;
      font-weight: 700;
      margin: 0 8px 8px 0;
    }
  }
}

.dropdown {
  height: 20px;
  width: 178px
}

.btnContainer {
  display: flex;
  margin-right: 20px;
  .titleBtn {
    min-height: 20px;
    height: 20px;
    padding: 5px 10px;
    font-weight: 500;
    font-size: var(--primary-text-color);
    line-height: 0;
  }
}

.loading {
  top: 60px;
}
