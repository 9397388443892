.datePicker {
  padding: 19px 6px 4px;
  width: 100px;
  height: 40px;
  border: solid 1px var(--color-gray-100);
  color: var(--color-gray-600);
  font-weight: 500;
  cursor: pointer;

  &Wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    color: var(--color-gray-800);
    box-shadow: var(--bg-shadow);
    border-radius: 4px;
    margin: 0 10px;
    height: 40px;
  }

  &Start{
    border-right: none;
    border-radius: 4px 0 0 4px;
    width: 120px;
    padding-left: 35px;
    text-align: center;
    background-color: white;
  }

  &End {
    border-left: none;
    border-radius: 0 4px 4px 0;
    background-color: white;
    text-align: left;
  }

  &WrapperDark {
    color: white;
    background-color: var(--color-purple-100);
    box-shadow: var(--bg-shadow-purple);

    .datePickerStart,
    .datePickerEnd {
      background-color: var(--color-purple-100);
      color: white;
      border-color: var(--color-purple-100);
      font-weight: 700;
    }

    .mdash {
      border: none;
      background-color: var(--color-purple-100);
      font-weight: 700;
    }

    .icon {
      color: white;
    }
  }
}

.timePeriod {
  position: absolute;
  top: 3px;
  z-index: 1;
  left: 39px;
  font-weight: bold;
  font-size: 12px;
  opacity: 0.6;
}

.mdash {
  display: flex;
  align-items: center;
  height: 40px;
  border-top: solid 1px var(--color-gray-100);
  border-bottom: solid 1px var(--color-gray-100);
  padding-top: 15px;
}

.icon {
  position: absolute;
  color: var(--color-gray-600);
  left: 8px;
  z-index: 1;
}

.disabled {
  opacity: .6;

  .icon {
    opacity: .6;
  }
}
