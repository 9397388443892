.errorContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 376px;
  height: 112px;
  margin-bottom: 12px;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(49, 50, 54, 0.2);
  background-color: white;
  padding: 24px 16px;
  position: absolute;
  right: -400px;
  animation: move 4s linear;
}

.icon {
  width: 45px;
  height: 45px;
  color: var(--color-red-400);
  margin-right: 19px;
}

.title {
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 4px;
  font-size: 16px;
}

.url {
  margin-bottom: 4px;
}

@keyframes move {
  0% {
    right: -400px;
  }
  10% {
    right: 15px;
  }
  90% {
    right: 15px;
  }
  100% {
    right: -400px;
  }
}
