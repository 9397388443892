@import '../../../../static/styles/_mixins.css';

.chartSidebar {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 30px 0 0 20px;
  border-right: 1px solid var(--color-gray-350);
  z-index: 5;
}

.chartTitle {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  position: absolute;
}

.icon {
  margin-right: 8px;
  width: 24px;
  height: 24px;
  margin-left: 20px;
}

.chartLabels {
  position: absolute;
  width: 280px;

  &Lab {
    position: absolute;
    width: 280px;
    height: 161px;
    padding: 10px 0 10px 20px;
    border-top: 1px solid var(--color-gray-350);

    &:last-child {
      border-bottom: 1px solid var(--color-gray-350);
    }
  }
}

.chartLabel {
  height: 40px;
  border-bottom: 1px solid var(--color-gray-350);
  display: flex;
  align-items: center;
  padding-left: 20px;

  &:first-child {
    height: 41px;
    border-top: 1px solid var(--color-gray-350);
  }

  & .label {
    @mixin textOverflow;
    width: 100%;
    height: 1.2em;
    cursor: default;
  }

  & .empty {
    margin-left: 8px;
    margin-right: 20px;
    color: var(--color-gray-800-opacity-70);
    white-space: nowrap;
  }
}

.labResults {
  line-height: 1.3;
  color: var(--color-gray-800-opacity-70);
  padding-top: 5px;
  margin-right: 10px;

  & span:nth-child(1) {
    width: 38px;
  }
  & span:nth-child(2) {
    width: 60px;
  }
  & span:nth-child(3) {
    width: 74px;
  }
  & span:nth-child(4) {
    width: 74px;
  }

  & span {
    display: inline-block;
    text-align: center;
  }

  &Table {
    height: 128px;
    overflow: auto;
    overflow-x: hidden;
    margin-top: 5px;
  }
}
