.wrapper {
  display: flex;
  flex-direction: column;
  margin-top: -80px;
  width: 100%;
}

.header {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 52px;
  min-width: 200px;
  font-weight: bold;
  font-size: 14px;
  justify-content: flex-end;
  color: var(--color-gray-600);
  padding-right: 36px;
  &Inactive {
    pointer-events: none;
  }
}

.tab {
  display: flex;
  align-items: center;
  padding: 0 12px;
  transition: .2s;
  border-bottom: 3px solid transparent;

  &:hover {
    cursor: pointer;
    color: var(--color-purple-100);
    border-bottom: 3px solid var(--color-purple-100);
  }

  &Active {
    color: var(--color-purple-100);
    border-bottom: 3px solid var(--color-purple-100);
  }

  &Bullet:before {
    position: relative;
    content: '';
    left: 0;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--color-purple-700);
    margin-right: 8px;
  }
}

.container {
  width: 100%;
  margin-top: 20px;
}
