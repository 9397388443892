@define-mixin textOverflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@define-mixin loading {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 12px;
}

@define-mixin disableButton {
  background-color: var(--color-purple-100);
  pointer-events: none;
  opacity: 0.6;
}

@define-mixin casesContainer {
  height: 230px;
  display: flex;
  flex-direction: column;
  margin-bottom: 6px;
}

@define-mixin casesContainerTitle {
  padding-left: 8px;
  margin-top: 18px;
  margin-bottom: 6px;
  white-space: nowrap;
}
