.wrapper {
  position: relative;


  .selectList {
    height: auto;
    overflow: auto;
    width: 100%;

    .listElement {
      font-size: 14px;
      color: var(--color-gray-400);
      padding: 7px;
      width: 100%;
      border-radius: 4px;

      &:hover {
        background-color: var(--color-purple-100);
        cursor: pointer;
        color: var(--color-white);
      }
    }



    &Container {
      display: flex;
      position: absolute;
      left: 0;
      list-style-type: none;
      background: white;
      box-shadow: var(--bg-shadow);
      border: solid 1px var(--color-gray-100);
      border-radius: 4px;
      white-space: nowrap;
      margin: 4px 10px 0 10px;
      right: -3px;
      z-index: 100;
    }
  }
}

