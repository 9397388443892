@import '../../static/styles/_mixins.css';

.tableWrapper {
  background-color: white;
  margin-top: 20px;
}

.cell {
  padding: 18px 4px;
  text-align: center;
  border-bottom: 1px solid var(--color-gray-200);

  &Header {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: var(--color-gray-400);
    font-weight: bold;
    cursor: pointer;

    & > span {
      margin-right: 4px;
      line-height: 1em;
    }
    &Disabled {
      cursor: default;
    }
  }
}

.Table {
  width: 100%;
  margin-top: 15px;
}

.headerRow {
  font-size: 12px;
  color: var(--color-gray-400);
  font-weight: bold;
  border-bottom: 1px solid var(--color-gray-200);
  text-align: center;
  user-select: none;
  white-space: nowrap;
}

.headerClickableRow {
  cursor: pointer;
}

.evenRow,
.oddRow {
  border-bottom: 1px solid var(--color-gray-200);
  text-align: center;
}

.headerColumn {
  text-transform: none;
  white-space: pre-wrap;
}

.exampleColumn {
  @mixin textOverflow;
}

.noRows {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  color: var(--color-gray-600);
}

.icon {
  width: 16px;
  height: 16px;
  color: var(--color-gray-400);
  flex-shrink: 0;
}

.loading {
  top: auto;
  bottom: 8px;
  background-color: transparent;
}
