.loadMore {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: var( --color-purple-100);
    margin-top: 90px;
    background: none;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
}

.rightContent {
    width: 100%;
    max-width: 313px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
    padding: 0 40px;
    overflow: scroll;
    max-height: 440px;

    .searchRight {
        width: 232px;
        height: 40px;
        box-shadow: 0 2px 4px 0 rgba(49, 50, 54, 0.08);
        background-color: #fff;
    }

    &ListWrapper {
        width: 100%;
        margin-top: 16px;
        padding-bottom: 100px;
        overflow-x: auto;
    }


    &List {
        max-width: 310px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-content: center;
        align-items: center;
        width: 100%;

        .clickWrapper {
            width: 100%;

            :hover {
                cursor: pointer;
            }
        }

        .listRight {
            position: relative;
            cursor: default;
            list-style-type: none;
            height: 40px;
            border-radius: 4px;
            background-color: #f2f4fa;
            display: flex;
            align-items: center;
            width: 100%;
            margin-bottom: 4px;
            max-width: 232px;
            justify-content: space-between;

            .plus {
                display: none;
                background-color: var(--color-pirple-light-100);
                width: 24px;
                height: 24px;
                justify-content: center;
                align-items: center;
                align-content: center;
                border: none;
                border-radius: 4px;
                margin-right: 8px;
                background-image: url("../../../../static/images/plus-purple.webp");
                background-size: 24px 24px;
            }

            .itemRight {
                font-size: 14px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.14;
                letter-spacing: normal;
                color: var(--color-gray-800);
                padding-left: 8px;
                opacity: 0.7;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 196px;
                display: block;
                display: -webkit-box;
            }

            .show {
                position: absolute;
                z-index: 10000;
                margin-top: 104px;
                margin-left: 50px;
                width: 139px;
                height: 64px;
                border-radius: 4px;
                box-shadow: 0 2px 4px 0 rgba(49, 50, 54, 0.08);
                border: solid 1px var(--color-gray-100);
                flex-direction: column;
                background-color: #fff;

                .contentShow {
                    flex-direction: column;
                    display: flex;

                    .showButton {
                        padding-left: 8px;
                        width: 135px;
                        height: 30px;
                        cursor: pointer;
                        background-color: #fff;
                        text-align: start;
                        font-size: 14px;
                        font-weight: 500;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.14;
                        letter-spacing: normal;
                        color: #838486;
                        border: none;

                        &:hover {
                            color: #fff;
                            border-radius: 4px;
                            box-shadow: 0 2px 4px 0 rgba(49, 50, 54, 0.08);
                            background-color: var(--color-purple-100);
                        }

                        &:disabled {
                            cursor: inherit;
                            color: lightgray;
                            box-shadow: none;
                            background-color: inherit;
                        }
                    }


                }


            }
        }

        .listRight:hover {
            background-color: var(--color-pirple-light-100);

            .plus {
                cursor: pointer;
                display: flex;
            }

            .itemRight {
                opacity: 1;
            }
        }
    }
}