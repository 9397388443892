.tableRow{
	display: grid;
    grid-template-columns: minmax(auto, 90px) minmax(160px, 247px) minmax(180px, 213px) minmax(180px, 200px) minmax(180px, 200px) minmax(180px, 200px) minmax(auto, 135px);
    grid-column-gap: 4px;
    grid-auto-rows: 50px;
    align-items: center;
	
    .cellHeader {
        font-size: .85em;
        color: var(--color-gray-400);
        font-weight: 800;
        display: flex;

        &.cellRank {
            cursor: pointer;
        }
    }
}

.icon {
    width: 14px;
    height: 14px;
    margin-left: 4px;
    color: var(--color-gray-400);
}