@import '../../../static/styles/_mixins.css';

.checkbox {
  appearance: auto;
  margin-right: 8px;
  position: relative;
}

label {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 12px 24px 12px 8px;
  user-select: none;
  cursor: pointer;

  & span {
    @mixin textOverflow;
  }
}

.customCheckbox {
  position: absolute;
  appearance: auto;
  z-index: -1;
  opacity: 0;
}

.customCheckbox+label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}

.customCheckbox+label::before {
  content: '';
  display: inline-block;
  width: 1em;
  height: 1em;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid var(--color-gray-100);
  border-radius: 0.25em;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
}

.customCheckbox:not(:disabled):not(:checked)+label:hover::before {
  border-color: var(--color-gray-100);
}

.customCheckbox:not(:disabled):active+label::before {
  border-color: var(--color-gray-100);
}

.customCheckbox:focus+label::before {
  box-shadow: 0 2px 4px 0 rgba(49, 50, 54, 0.08);
}

.customCheckbox:focus:not(:checked)+label::before {
  box-shadow: 0 2px 4px 0 rgba(49, 50, 54, 0.08);
}

.customCheckbox:checked+label::before {
  background-color: white;
  background-image: url("../../../static/images/checkmark.svg");
}

.customCheckbox+label::before {
  background-color: white;
}

.customCheckbox:disabled+label::before {
  background-color: var(--color-gray-100);
}
.customCheckbox:disabled+label {
  opacity: 0.7;
}
