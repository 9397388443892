.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;

  .title {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    margin-bottom: 16px;
  }
  .text {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    margin-bottom: 40px;
  }
}
